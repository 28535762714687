import { faEnvelope, faLocationDot, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { BlobMemo } from "../Blob/Blob";
import "./personalInfo.scss";
import Nut from "../Nut/Nut";

const PersonalInfo = () => {
  return(
    <div className="container info-container">
      <div className="info my-card">
        <div className="nut">
          <Nut />
        </div>
          <label className="name font-primary">Andrea Pennisi</label>
          <div className="contact">
            <a href="https://t.me/apnnux">
              <i className="icon-telegram" />
              <label>@apnnux</label>
            </a>
            <a href="mailto:andry.pennisi@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} />
              <label>andry.pennisi@gmail.com</label>
            </a>
            <a href="https://maps.google.it/?q=37.5029%2C15.087" target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faLocationDot} className="loc" />
              <label>Catania</label>
            </a>
            <a href="/docs/CV_Andrea_Pennisi.pdf" download>
              <FontAwesomeIcon icon={faFilePdf} />
              <label>CV</label>
            </a>
          </div>
      </div>
      <BlobMemo />
    </div>
  )
}

export default PersonalInfo;