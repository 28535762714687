import { faCookieBite, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import "./cookieBar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const CookieBar = ({lang, theme, alreadyAccept, triggeredLocalTranslate}: {lang: 'it' | 'en', theme: 'light' | 'dark', alreadyAccept: boolean, triggeredLocalTranslate: any}) => {
  const [isAccept, setAccept] = useState<boolean>(alreadyAccept);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [collapseTable, setCollapseTable] = useState<boolean>(true);
  const [restartTransition, setRestartTransition] = useState<boolean>(false);

  useEffect(() => {
    if (isAccept) {
      cookies.set('lang', lang, { path: '/', expires: new Date(Date.now()+(31556926*1000)) /* 1 year */ });
    }
  }, [lang, isAccept]);

  useEffect(() => {
    if (isAccept) {
      cookies.set('theme', theme, { path: '/', expires: new Date(Date.now()+(31556926*1000)) /* 1 year */ });
    }
  }, [theme, isAccept]);

  useEffect(() => {
    if (!isAccept && openInfo) {
      setCollapseTable(false);
      setTimeout(() => {
        setRestartTransition(true)
      }, 100);
    }else{
      setRestartTransition(false);
      setTimeout(() => {
        setCollapseTable(true)
      }, 1000);
    }
  }, [isAccept, openInfo])

  return(
    <>
      <div className={`cookie-container my-card fab ${isAccept ? 'visible' : ''}`} onClick={() => {setAccept(false)}}>
        <FontAwesomeIcon icon={faCookieBite} />
      </div>
      <div className={`cookie-container container ${isAccept ? 'accepted' : ''}`}>
        <div className={`cookie-table ${restartTransition && !isAccept && openInfo ? '' : 'hide-table'} ${collapseTable ? 'collapse' : ''}`}>
          <div className={`cookie-single-container title ${restartTransition && !isAccept && openInfo ? 'open' : ''}`}>
            <div className="my-card cookie-name font-primary">
              {triggeredLocalTranslate.cookie_title_name}
            </div>
            <div className="my-card cookie-description font-primary">
              {triggeredLocalTranslate.cookie_title_desc}
            </div>
            <div className="my-card cookie-duration font-primary">
              {triggeredLocalTranslate.cookie_title_duration}
            </div>
          </div>
          <div className={`cookie-single-container lang ${restartTransition && !isAccept && openInfo ? 'open' : ''}`}>
            <div className="my-card cookie-name font-primary">
              lang
            </div>
            <div className="my-card cookie-description">
              {triggeredLocalTranslate.cookie_lang_desc}
            </div>
            <div className="my-card cookie-duration">
              {triggeredLocalTranslate.cookie_lang_duration}
            </div>
          </div>
          <div className={`cookie-single-container theme ${restartTransition && !isAccept && openInfo ? 'open' : ''}`}>
            <div className="my-card cookie-name font-primary">
              theme
            </div>
            <div className="my-card cookie-description">
              {triggeredLocalTranslate.cookie_theme_desc}
            </div>
            <div className="my-card cookie-duration">
              {triggeredLocalTranslate.cookie_theme_duration}
            </div>
          </div>
        </div>
        <div className="my-card modal-wrapper">
          <div>
            <FontAwesomeIcon icon={faCookieBite} />
            {triggeredLocalTranslate.cookie_info}
            <div className="info-popup">
              <FontAwesomeIcon icon={faCircleInfo} className="info"  onClick={() => setOpenInfo(!openInfo)} />
            </div>
          </div>
          <button className="confirm" onClick={() => setAccept(true)}>{triggeredLocalTranslate.cookie_accept}</button>
        </div>
      </div>
    </>
  )
}

export default CookieBar;