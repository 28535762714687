import React from "react";
import "./social.scss"

const Social = () => {
  return(
    <>
      <div id="social"><ul>
          <li><a href="https://github.com/apnnux" target="_blank"><i className="fa-brands fa-github github"></i></a></li>
          <li><a href="https://www.linkedin.com/in/a-pennisi/" target="_blank"><i className="fa-brands fa-linkedin linkedin"></i></a></li>
          <li><a href="mailto:andry.pennisi@gmail.com"><i className="fa-solid fa-envelope"></i></a></li>

      </ul></div>
    </>
  )
}

export default Social;