import React from "react";
import "./asauriti.scss";
import Logo from "../../../../assets/imgs/coca-logo.png";

const Asauriti = ({triggeredTranslate}: {triggeredTranslate: any}) => {
  return(
    <div id="asauriti" className="my-card clickable">
      <div className="title">
        <img src={Logo} alt="Asauriti" className="logo" width={100}/>
        <div className="d-inline-flex flex-column">
          <label className="work font-primary">Asauriti 2</label>
          <label className="description font-primary">
            Un'app per le comunità capi
          </label>
        </div>
      </div>
      
      <i className="icon-flutter map" />
      <i className="fa-solid fa-circle-info link"></i>
    </div>
  )
}

export default Asauriti;