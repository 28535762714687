import React from "react";
import { Blob as BlobC } from 'react-blob'
import "./blob.scss"
import Andrea from "../../assets/imgs/andrea_pennisi.jpg";

let Blob = () => {
  return(
    <div id="blob" className="blob-container">
      <BlobC
        size="200px"
        style={{
          zIndex: -1,
          backgroundColor: '#2F1018',
          boxShadow: '0 4px 40px 0 #2F1018'
        }}
      >
        <BlobC
          size="150px"
          style={{
            position: 'absolute',
            top: '25px',
            right: '25px',
            boxShadow: '0 4px 40px 0 #2F1018'
          }}
        >
          <img src={Andrea} width={200} height={200} alt="andrea pennisi"/>
        </BlobC>
      </BlobC>
    </div>
  )
}

export const BlobMemo = React.memo(Blob);