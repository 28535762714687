import React, { useState } from "react";
import "./unict.scss";
import Logo from "../../../assets/imgs/unict.png";
import { LatLngExpression } from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import icon from '../../../assets/imgs/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;


const UniCTM = ({triggeredTranslate}: {triggeredTranslate: any}) => {
  const position = [37.52571, 15.07288] as LatLngExpression;
  const [showBack, setBack] = useState(false);
  return(
    <div className={"card-flip " + (showBack ? 'retro' : '')}>
    <div className="content-flip">
      <div className="front">
        <a href="http://web.dmi.unict.it/" target="_blank" rel="noreferrer" id="unict" className="my-card clickable masterly">
          <img src={Logo} alt="skylabs" width={140}/>
          <label className="date">01/2024 - {triggeredTranslate.text_now}</label>
          <label className="work font-primary">{triggeredTranslate.text_unictm}</label>
          <i className="fa-solid fa-arrow-up-right-from-square link"></i>
          <i className="fa-solid fa-map-location-dot map" onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setBack(true);
                }}></i>
        </a>
      </div>
      <div className="back">
        <i className="fa-solid fa-x close" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setBack(false);
            }}></i>
        <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              DMI Unict
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  </div>
  )
}

export default UniCTM;