import React, { useState } from 'react';
import './app.scss';
import Header from './Components/Header/Header';
import Loader from './Components/Loader/Loader';
import PersonalInfo from './Components/PersonalInfo/PersonalInfo';
import Ferraris from './Components/Instruction/Ferraris/Ferraris';
import UniCT from './Components/Instruction/UniCT/UniCT';
import Skylabs from './Components/Instruction/Skylabs/Skylabs';
import { LocalTranslate } from './assets/lang/LocalTranslate';
import LocalizedStrings from 'react-localization';
import Salesforce from './Components/Salesforce/salesforce';
import CookieBar from './Components/CookieBar/CookieBar';
import Cookies from 'universal-cookie';
import TLG from './Components/Instruction/TLG/TLG';
import UniCTM from './Components/Instruction/UniCT/UniCTM';
import Skills from './Components/Skills/Skills';
import Projects from './Components/Projects/Projects';
import Linux from './Components/Linux/linux';


const cookies = new Cookies();

function App() {
  const langCookie = cookies.get('lang');
  const themeCookie = cookies.get('theme');
  const [lang, setLang] = useState<'it' | 'en'>(langCookie || 'en')
  const [theme, setTheme] = useState<'light' | 'dark'>(themeCookie || 'dark')
  const alreadyAccept = !!langCookie && !!themeCookie;
  const triggeredLocalTranslate = new LocalizedStrings(LocalTranslate);
  triggeredLocalTranslate.setLanguage(lang);

  return (
    <>
      {
        triggeredLocalTranslate ? (
          <>
            <div className={`body-cnt ${theme}-theme`}>
              <Header setLang={setLang} setTheme={setTheme} theme={theme} lang={lang} />
              <PersonalInfo />
              <div className='container split-container'>
                <Ferraris triggeredTranslate={triggeredLocalTranslate} />
                <UniCT triggeredTranslate={triggeredLocalTranslate} />
                <UniCTM triggeredTranslate={triggeredLocalTranslate} />
              </div>
              <div className='container split-container'>
                <Skylabs triggeredTranslate={triggeredLocalTranslate} />
                <TLG triggeredTranslate={triggeredLocalTranslate} />
              </div>
              {/* <div className='container split-container'>
                <Languages triggeredTranslate={triggeredLocalTranslate} />
                <Frameworks triggeredTranslate={triggeredLocalTranslate} />
              </div> */}
              <div className='container'>
                <Skills triggeredTranslate={triggeredLocalTranslate} />
              </div>
              <div className='container split-container'>
                <Salesforce triggeredTranslate={triggeredLocalTranslate} />
                <Linux triggeredTranslate={triggeredLocalTranslate} />
              </div>
              <div className='container'>
                <Projects triggeredTranslate={triggeredLocalTranslate} />
              </div>
              <Loader triggeredTranslate={triggeredLocalTranslate} />
              <div className='copyright container reverse'>
                Andrea Pennisi © 2024
              </div>
            </div>
            <CookieBar lang={lang} theme={theme} alreadyAccept={alreadyAccept} triggeredLocalTranslate={triggeredLocalTranslate} />
          </>
        ) : <></>
      }
    </>
  );
}

export default App;