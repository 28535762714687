export const LocalTranslate = {
  it: {
    text_ferraris: 'Informatica',
    text_unict: 'Informatica (L-31)',
    text_unictm: 'Data science (LM-18)',
    text_skylabs: 'Sviluppatore web',
    text_tlg: 'Sviluppatore full stack',
    text_now: 'adesso',
    text_lang: 'Linguaggi',
    text_tech: 'Tecnologie',
    text_projects: 'Progetti',
    text_soon: 'Prossimamente...',
    b2c_dev: 'Sviluppatore Commerce Cloud',
    linux_usr: 'Utente Linux',
    text_cert: 'Certificazioni',
    cookie_info: 'Questo sito utilizza cookie tecnici. Consulta la politica dei cookies per avere ulteriori informazioni.',
    cookie_accept: 'Ho capito',
    cookie_title_name: 'Nome',
    cookie_title_desc: 'Descrizione',
    cookie_title_duration: 'Durata',
    cookie_lang_desc: 'Questo cookie è salvato nel dispositivo e memorizza la scelta della lingua da parte dell\'utente',
    cookie_lang_duration: '1 anno',
    cookie_theme_desc: 'Questo cookie è salvato nel dispositivo e memorizza la scelta del tema da parte dell\'utente',
    cookie_theme_duration: '1 anno'
  },
  en: {
    text_ferraris: 'Computer technology',
    text_unict: 'Computer science (L-31)',
    text_unictm: 'Data science (LM-18)',
    text_skylabs: 'Web developer',
    text_tlg: 'Full Stack developer',
    text_now: 'now',
    text_lang: 'Languages',
    text_tech: 'Technologies',
    text_projects: 'Projects',
    text_soon: 'Coming soon...',
    b2c_dev: 'Commerce Cloud Developer',
    linux_usr: 'Linux User',
    text_cert: 'Certifications',
    cookie_info: 'This site uses technical cookies. Consult the cookies policy for more information.',
    cookie_accept: 'I understand',
    cookie_title_name: 'Name',
    cookie_title_desc: 'Description',
    cookie_title_duration: 'Duration',
    cookie_lang_desc: 'This cookie is saved on the device and stores the user\'s choice of language',
    cookie_lang_duration: '1 year',
    cookie_theme_desc: 'This cookie is saved on the device and stores the user\'s choice of theme',
    cookie_theme_duration: '1 year'
  }
}