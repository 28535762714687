import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./header.scss";
import Social from "../Social/Social";
import LanguageSwitch from "../LanguageSwitch/LanguageSwitch";
import ThemeSwitch from "../ThemeSwitch/themeSwitch";

const Header = ({setLang, setTheme, theme, lang}: {setLang: React.Dispatch<React.SetStateAction<"it" | "en">>, setTheme: React.Dispatch<React.SetStateAction<"light" | "dark">>, theme: "light" | "dark", lang: "it" | "en"}) => {
  return(
    <>
      <Navbar bg={theme === "dark" ? 'black' : "white"} variant={theme} expand="md" className="header">
        <Container>
          <Navbar.Brand href="#home" className="font-primary d-flex">
            Portfolio
            <LanguageSwitch setLang={setLang} lang={lang} />
            <ThemeSwitch setTheme={setTheme} theme={theme} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav >
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse id="basic-navbar-nav-end" className="justify-content-end">
            <Nav>
              <Social />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Header;