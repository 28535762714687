import React from "react";
import "./salesforce.scss";
import Logo from "../../assets/imgs/salesforce.svg";
import B2CDev from "../../assets/imgs/B2C-Commerce-Developer.png";

const Salesforce = ({triggeredTranslate}: {triggeredTranslate: any}) => {
  return(
    <a href="https://www.salesforce.com/" target="_blank" rel="noreferrer" id="salesforce" className="my-card clickable">
      <div className="title">
        <img src={Logo} alt="Salesforce" className="logo" width={100}/>
        <label className="work font-primary">{triggeredTranslate.b2c_dev}</label>
      </div>
      {/* <label className="cert font-primary">{triggeredTranslate.text_cert}</label>
      <div className="b2c_dev">
        <img src={B2CDev} alt="B2C Commerce Cloud" width={70}/>
        <div className="cert_desc">
          <label className="desc">B2C Commerce Developer</label>
          <label className="date">10/2023</label>
        </div>
      </div> */}
      
      <i className="fa-solid fa-arrow-up-right-from-square link"></i>
    </a>
  )
}

export default Salesforce;