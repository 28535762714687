import React, { useState } from "react";
import "./skylabs.scss";
import Logo from "../../../assets/imgs/logo-skylabs.svg";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { LatLngExpression } from "leaflet";
import icon from '../../../assets/imgs/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import L from 'leaflet';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const Skylabs = ({triggeredTranslate}: {triggeredTranslate: any}) => {
  const position = [37.50748, 15.08964] as LatLngExpression;
  const [showBack, setBack] = useState(false);
  return(
    <div className={"card-flip " + (showBack ? 'retro' : '')}>
    <div className="content-flip">
      <div className="front">
        <a href="https://skylabs.it" target="_blank" rel="noreferrer" id="skylabs" className="my-card clickable">
          <img src={Logo} alt="skylabs" width={140}/>
          <label className="date">06/2021 - 02/2024</label>
          <label className="work font-primary">{triggeredTranslate.text_skylabs}</label>
          <i className="fa-solid fa-arrow-up-right-from-square link"></i>
          <i className="fa-solid fa-map-location-dot map" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setBack(true);
            }}></i>
        </a>
      </div>
      <div className="back">
        <i className="fa-solid fa-x close" onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setBack(false);
            }}></i>
        <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={position}>
            <Popup>
              Skylabs s.r.l
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  </div>
  )
}

export default Skylabs;