import './nut.scss';

const Nut = () => {
  return(
    <div className="loader-container">
      <div className="loader-cube">
        <div className="loader-side front-nut"></div>
        <div className="loader-side back-nut"></div>
        <div className="loader-side left"></div>
        <div className="loader-side right"></div>
        <div className="loader-side top"></div>
        <div className="loader-side bottom"></div>
      </div>
    </div>
  )
}

export default Nut;