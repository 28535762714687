import React from "react";
import "./languageSwitch.scss"

const LanguageSwitch = ({setLang, lang}: {setLang: React.Dispatch<React.SetStateAction<"it" | "en">>, lang: "it" | "en"}) => {
  return(
    <div className="lang">
      <div className="tabber">
        <input id="en" name="food" type="radio" checked={lang === "en"} onChange={e => setLang('en')} />
        <label className="enlab" htmlFor="en">EN</label>
        <input id="it" name="food" type="radio" checked={lang === "it"} onChange={e => setLang('it')}/>
        <label className="itlab" htmlFor="it">IT</label>
        <div className="blob"></div>
      </div>
    </div>
  );
}

export default LanguageSwitch;