export const LANGUAGES: {name: string, url: string}[] =[
  {name: 'C', url: 'c.png'},
  {name: 'C++', url: 'cpp.png'},
  {name: 'C#', url: 'cs.png'},
  {name: 'Java', url: 'java.png'},
  {name: 'PHP', url: 'php.png'},
  {name: 'Python', url: 'python.png'},
  {name: 'HTML', url: 'html.png'},
  {name: 'CSS', url: 'css.png'},
  {name: 'Sass', url: 'sass.png'},
  {name: 'JavaScript', url: 'js.png'},
  {name: 'TypeScript', url: 'ts.png'},
  {name: 'XML', url: 'xml.png'},
  {name: 'Dart', url: 'dart.png'},
  {name: 'SQL', url: 'sql.png'},
];

export const TECHNOLOGIES: {name: string, url: string}[] =[
  {name: 'Angular', url: 'angular.png'},
  {name: 'JQuery', url: 'jquery.png'},
  {name: 'React', url: 'react.png'},
  {name: 'Flutter', url: 'flutter.png'},
  {name: 'Bootstrap', url: 'bootstrap.png'},
  {name: 'Laravel', url: 'laravel.png'},
  {name: 'Yii 2', url: 'yii2.png'},
  {name: 'Spring', url: 'spring.png'},
  {name: 'TailwindCSS', url: 'tailwind.png'},
  {name: 'XAMPP', url: 'xampp.png'},
  {name: 'Strapi', url: 'strapi.png'},
  {name: 'Moodle', url: 'moodle.png'},
  {name: 'Figma', url: 'figma.png'},
  {name: 'Android', url: 'android.png'},
  {name: 'Blender', url: 'blender.png'},
  {name: 'Unity', url: 'unity.png'},
  {name: 'Git', url: 'git.png'},
  {name: 'MySQL', url: 'mysql.png'},
  {name: 'Firebase', url: 'firebase.png'},
  {name: 'NodeJS', url: 'nodejs.png'},
  {name: 'NestJS', url: 'nestjs.png'},
  {name: 'Atlassian Suite', url: 'atlassian.png'},
  {name: 'Office suite', url: 'office.png'},
];