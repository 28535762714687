import React from "react";
import "./linux.scss";
import Logo from "../../assets/imgs/linux.png";

const Linux = ({triggeredTranslate}: {triggeredTranslate: any}) => {
  return(
    <a href="https://www.linux.com/" target="_blank" rel="noreferrer" id="linux" className="my-card clickable">
      <div className="title">
        <img src={Logo} alt="Salesforce" className="logo" width={100} height={80}/>
        <label className="work font-primary">{triggeredTranslate.linux_usr}</label>
      </div>
      <i className="fa-solid fa-arrow-up-right-from-square link"></i>
    </a>
  )
}

export default Linux;