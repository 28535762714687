import React from "react";
import Asauriti from "./Project/Asauriti2/Asauriti";

const Projects = ({triggeredTranslate}: {triggeredTranslate: any}) => {
  return(
    <>
      <h2 className="title font-primary mt-5 ms-4">{triggeredTranslate.text_projects}</h2>
      <Asauriti triggeredTranslate={triggeredTranslate} />
    </>
  )
}

export default Projects;