import { LANGUAGES, TECHNOLOGIES } from "./Content/languages"
import './skills.scss';

const Skills = ({triggeredTranslate}: {triggeredTranslate: any}) => {
  return <>
    <h2 className="title font-primary mt-5 ms-4">{triggeredTranslate.text_lang}</h2>
    <div className="my-card lang mt-3">
      <div className='scroll-container'>
        {LANGUAGES.map((img) => {
          return (
            <img alt={img.name} title={img.name} src={'/skills/' + img.url} className="scroll-tile" />
            )
        })}

        {LANGUAGES.map((img) => {
          return (
            <img alt={img.name} title={img.name} src={'/skills/' + img.url} className="scroll-tile" />
            )
        })}
      </div>
    </div>
    <h2 className="title font-primary mt-5 ms-4">{triggeredTranslate.text_tech}</h2>
    <div className="my-card tech mt-3">
      <div className='scroll-container'>
        {TECHNOLOGIES.map((img) => {
          return (
            <img alt={img.name} title={img.name} src={'/skills/' + img.url} className="scroll-tile" />
            )
        })}

        {TECHNOLOGIES.map((img) => {
          return (
            <img alt={img.name} title={img.name} src={'/skills/' + img.url} className="scroll-tile" />
            )
        })}
      </div>
    </div>
  </>
}

export default Skills;