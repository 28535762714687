import React from "react";
import "./themeSwitch.scss";

const ThemeSwitch = ({setTheme, theme} : {setTheme: React.Dispatch<React.SetStateAction<"light" | "dark">>, theme: "light" | "dark"}) => {
  return(
    <div className="toggleWrapper">
  <input type="checkbox" className="dn" id="dn" onChange={(e) => setTheme(e.target.checked ? "dark" : "light")} checked={theme === "dark"} />
  <label htmlFor="dn" className="toggle">
    <span className="toggle__handler">
      <span className="crater crater--1"></span>
      <span className="crater crater--2"></span>
      <span className="crater crater--3"></span>
    </span>
    <span className="star star--1"></span>
    <span className="star star--2"></span>
    <span className="star star--3"></span>
    <span className="star star--4"></span>
    <span className="star star--5"></span>
    <span className="star star--6"></span>
  </label>
</div>
  )
}

export default ThemeSwitch;